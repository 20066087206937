<script setup lang="ts">
import {toTypedSchema} from "@vee-validate/zod";
import * as z from "zod";
import {useForm} from "vee-validate";
import Error from "~/components/layouts/notice/Error.vue";
import {OTPSend} from "~/services/user-management-service";
import {Vue3Lottie} from "vue3-lottie";

const statesStore = useStatesStore();
const {otp_verification} = useAuthLogics();
const Loader = useIsLoadingStore()
const reSendOTPActive = ref(true)

const SendOTP = () => {
  if (statesStore.UserEmail) {
    OTPSend(
        {
          'email': statesStore.UserEmail
        }
    )
    reSendOTPActive.value = false
    setTimeout(() => {
      reSendOTPActive.value = true
    }, 60000)
  }
  if (statesStore.UserPhone) {
    OTPSend(
        {
          'phone_number': statesStore.CurrentDialCode.dial_code + statesStore.UserPhone
        }
    )
    reSendOTPActive.value = false
    setTimeout(() => {
      reSendOTPActive.value = true
    }, 60000)
  }
}
SendOTP()


const formSchema = toTypedSchema(z.object({
  pin: z.array(z.coerce.string()).length(4, {message: 'Invalid input'}),
}))


const {handleSubmit, setValues, meta} = useForm({
  validationSchema: formSchema,
  initialValues: {
    pin: [],
  },
})

const handleOTPSubmit = handleSubmit(({pin}) => {
  if (statesStore.UserEmail) {
    otp_verification('email', statesStore.UserEmail, pin.join(''))
  }
  if (statesStore.UserPhone) {
    otp_verification('phone_number', statesStore.CurrentDialCode.dial_code + statesStore.UserPhone, pin.join(''))
  }
})

const handleComplete = (e: string[]) => {
  if (statesStore.UserEmail) {
    otp_verification('email', statesStore.UserEmail, e.join(''))
  }
  if (statesStore.UserPhone) {
    otp_verification('phone_number', statesStore.CurrentDialCode.dial_code + statesStore.UserPhone, e.join(''))
  }
}
</script>

<template>
  <UiDialogHeader>
    <CustomUiSectionLogoIcon class="m-auto mb-2" icon_name="ri:mail-check-fill"/>
    <UiDialogTitle class="text-center">{{ $t("Enter Verification Code") }}</UiDialogTitle>
    <UiDialogDescription v-if="statesStore.UserEmail.length" class="text-gray-500 border-b-gray-300 border-b pb-5 text-center">
      {{ $t("We’ve sent a code to") }} {{ statesStore.UserEmail }} !
    </UiDialogDescription>
    <UiDialogDescription v-if="statesStore.UserPhone.length" class="text-gray-500 border-b-gray-300 border-b pb-5 text-center">
      {{ $t("We’ve sent a code to") }} {{ statesStore.CurrentDialCode.dial_code }} {{ statesStore.UserPhone }}!
    </UiDialogDescription>
  </UiDialogHeader>

  <UiFormField v-slot="{ componentField, value }" name="pin">
    <UiFormItem class="text-center my-6">
      <UiFormControl>
        <UiPinInput
            id="pin-input"
            v-model="value!"
            placeholder="○"
            class="flex gap-2 items-center mt-1"
            otp
            type="number"
            :name="componentField.name"
            @complete="handleComplete"
            @update:model-value="(arrStr) => {
              setValues({
                pin: arrStr.filter(Boolean),
              })
            }"
        >
          <UiPinInputGroup class="text-4xl">
            <UiPinInputInput
                v-for="(id, index) in 4"
                :key="id"
                :index="index"
                class="text-2xl"
            />
          </UiPinInputGroup>
        </UiPinInput>
      </UiFormControl>
      <UiFormMessage/>
    </UiFormItem>
  </UiFormField>
  <Error class="mb-6" v-if="statesStore.IsErrorNotice && meta.valid"
         :message="$t('Wrong validation code. Try again.')"/>


  <UiDialogFooter class="block">
    <UiButton class="ys-button w-full"
              @click="handleOTPSubmit"
              :disabled="Loader.isLoading">
      {{ $t("Submit Code") }}
    </UiButton>
    <div class="text-center">
      <div class="ys-otp-question-text mt-6 mb-1">{{ $t("Experiencing issues receiving the code?") }}</div>
      <a v-if="reSendOTPActive"
              @click="SendOTP" class="underline link-button" style="font-size: 14px">{{ $t("Resend code") }}
      </a>
      <client-only v-if="!reSendOTPActive">
        <Vue3Lottie
            animationLink='/Loader/loader-p.json'
            :height="30"
            :width="30"
        />
      </client-only>
    </div>
  </UiDialogFooter>
</template>

<style scoped lang="scss">

.ys-otp-question-text {
  font-size: 14px;
  color: rgba(82, 88, 102, 1);
}

</style>