<script setup lang="ts">
import {onClickOutside} from '@vueuse/core';
import {ref} from "vue";
import ExistenceByEmail from "~/components/layouts/authForms/ExistenceByEmail.vue"; // Импорт компонента для проверки существования пользователя по email
import LoginByEmail from "~/components/layouts/authForms/LoginByEmail.vue"; // Импорт компонента для логина по email
import ExistenceByPhone from "~/components/layouts/authForms/ExistenceByPhone.vue"; // Импорт компонента для проверки существования пользователя по телефону
import LoginByPhone from "~/components/layouts/authForms/LoginByPhone.vue"; // Импорт компонента для логина по телефону
import ResetPassByEmail from "~/components/layouts/authForms/ResetPassByEmail.vue"; // Импорт компонента для сброса пароля по email
import ResetPassByPhone from "~/components/layouts/authForms/ResetPassByPhone.vue"; // Импорт компонента для сброса пароля по телефону

// Определение принимаемых пропсов
const props = defineProps({
  icon: Boolean, // Указывает, будет ли отображаться иконка
  className: String, // Класс для стилизации кнопки
  title: String, // Текст на кнопке
  startIcon: String // Иконка, которая будет отображена перед текстом
});

const target = ref(null); // Создание реактивной ссылки для отслеживания элемента, на который будет происходить клик
const activeTab = ref('email')
const statesStore = useStatesStore(); // Получение состояния приложения, связанного с пользовательскими статусами
const authStore = useAuthStore(); // Получение состояния авторизации пользователя

// Функция для очистки пользовательских состояний при клике вне диалогового окна
function clear() {
  statesStore.useClearCustomStates(); // Вызов метода для очистки состояний
}

// Использование onClickOutside для вызова функции clear при клике вне элемента, на который ссылается target
onClickOutside(target, clear);

watch(activeTab, (val, oldval) => {
  clear()
})
</script>

<template>
    <UiDialog class="">
      <!-- Триггер для открытия диалогового окна -->
      <UiDialogTrigger as-child>
        <!-- Кнопка с динамическими классами и контентом -->
        <UiButton :class="className || 'ys-button hover:text-amber-50'">
          <!-- Отображение иконки, если задан startIcon -->
          <Icon v-if="props.startIcon" size="18" class="ml-1" :name="props.startIcon"/>
          {{ props.title || $t("Join") }} <!-- Отображение текста кнопки -->
          <!-- Отображение иконки пользователя, если задано icon -->
          <Icon v-if="props.icon" size="18" class="ml-1" name="ri:user-line"/>
        </UiButton>
      </UiDialogTrigger>
      <!-- Содержимое диалогового окна, если пользователь не авторизован -->
      <UiDialogContent class="max-w-[440px]  ys-modal-dialog border-0" v-if="!authStore.isAuth">
          <UiDialogClose class="absolute top-4 ltr:right-4 rtl:left-4">
              <Icon name="ri:close-line" size="20px" class="text-gray-500 translate-all hover:text-gray-700"/>
          </UiDialogClose>
        <UiTabs style="padding: 32px;" default-value="email" ref="target" v-model="activeTab">
          <!-- Компоненты формы для различных состояний пользователя -->
          <ExistenceByEmail class="decor-unset"
                            v-if="statesStore.IsUserExist == 0 && !statesStore.UserForgotPassword || statesStore.IsUserExist == 2 && !statesStore.UserForgotPassword"/>
          <LoginByEmail class="decor-unset" v-if="statesStore.IsUserExist == 1 && !statesStore.UserForgotPassword"/>
          <ExistenceByPhone class="decor-unset"
                            v-if="statesStore.IsUserExist == 0 && !statesStore.UserForgotPassword || statesStore.IsUserExist == 2 && !statesStore.UserForgotPassword"/>
          <LoginByPhone class="decor-unset" v-if="statesStore.IsUserExist == 1 && !statesStore.UserForgotPassword"/>
          <ResetPassByEmail class="decor-unset" v-if="statesStore.UserForgotPassword"/>
          <ResetPassByPhone class="decor-unset" v-if="statesStore.UserForgotPassword"/>
        </UiTabs>
      </UiDialogContent>
    </UiDialog>
</template>

<style scoped lang="scss">
.decor-unset {
  box-shadow: unset;
}

.master-catalog-button-outline {
  color: rgba(45, 159, 117, 1);
  height: 40px;
  padding: 10px;
  gap: 4px;
  border-radius: 10px;
  border: 1px solid rgba(45, 159, 117, 1);
  background: white;
  box-shadow: 0 1px 2px 0 rgba(55, 93, 251, 0.08);

  &:hover {
    background: rgba(45, 159, 117, 1);
    color: white;
  }
}

.ys-header-nav-button {
  @apply my-auto cursor-pointer;
  color: rgba(82, 88, 102, 1);
  background: unset;
  text-decoration: unset;
  border-radius: 8px;
  font-size: 14px;
  height: 36px;
  padding: 8px 12px 8px 12px;
  gap: 8px;

  &:hover {
    background: rgba(246, 248, 250, 1);
  }
}

</style>