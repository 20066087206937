<script setup lang="ts">
import {toTypedSchema} from "@vee-validate/zod";
import * as z from "zod";
import {useForm, Form} from "vee-validate";
import Warning from "~/components/layouts/notice/Warning.vue";
import OTPVerifyForm from "~/components/layouts/authForms/OTPVerifyForm.vue";
import RegistrationForm from "~/components/layouts/authForms/RegistrationForm.vue";

const {locales, locale} = useI18n();
const currentLanguage = computed(() => {
  return locales.value.find(item => item.code === locale.value)
})
const Loader = useIsLoadingStore()

const {user_verification} = useAuthLogics();
const statesStore = useStatesStore();

const formSchema = toTypedSchema(z.object({
  email: z.string()
      .min(6, {message: "Email must be at least 6 characters long"})
      .max(50, {message: "Email must be no longer than 50 characters"})
      .email({message: "Invalid email format"})
      .regex(/^\S*$/, "Email must not contain spaces")
}))


const {handleSubmit, meta, values, setFieldValue} = useForm({
  validationSchema: formSchema,
})

const onSubmit = handleSubmit((values) => {
  user_verification('email', values.email);
  statesStore.UserPhone = ''
});


const OTPSend = () => {
  statesStore.UserPhone = ''
  statesStore.OTPIsVerify = false;
  statesStore.UserCreateAccount = true;
}

function handleInput(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  inputElement.value = inputElement.value.replace(/\s/g, '');
  statesStore.UserEmail = inputElement.value;
  setFieldValue('email', inputElement.value)
}

</script>

<template>
  <UiTabsContent value="email">
    <div v-if="statesStore.OTPIsVerify && !statesStore.UserCreateAccount">
      <UiDialogHeader v-if="statesStore.IsUserExist == 0">
        <CustomUiSectionLogoIcon class="m-auto mb-2" icon_name="ri:user-fill"/>
        <UiDialogTitle class="text-center">{{ $t("Login or register") }}</UiDialogTitle>
        <UiDialogDescription class="text-center text-gray-500 border-b-gray-300 border-b pb-5">
          {{ $t("Enter your details to login") }}
        </UiDialogDescription>
      </UiDialogHeader>

      <UiDialogHeader v-if="statesStore.IsUserExist == 2">
        <CustomUiSectionLogoIcon class="m-auto mb-2" icon_name="ri:user-fill"/>
        <UiDialogTitle class="text-center">{{ $t("Create a new account") }}</UiDialogTitle>
        <UiDialogDescription class="text-center text-gray-500 border-b-gray-300 border-b pb-5">
          {{ $t("Join us by e-mail") }}
        </UiDialogDescription>
      </UiDialogHeader>
      <form @submit="onSubmit">
        <UiFormField v-slot="{ componentField }" name="email">
          <UiFormItem class="relative mt-4 mb-6">
            <UiFormLabel>{{ $t("Email Address") }}</UiFormLabel>
            <UiFormControl>
              <UiInput type="email"
                       :class="{rtl_body_set: currentLanguage?.code === 'ar'}"
                       class="ys-form-input px-9"
                       @input="handleInput"
                       icon="ri:mail-line"
                       placeholder="hello@yourservice.com"
                       v-bind="componentField"/>
            </UiFormControl>
            <UiFormMessage />
          </UiFormItem>
        </UiFormField>
      </form>
      <Warning class="mb-6" v-if="statesStore.IsWarningNotice"
               :message="$t('The user with this email does not exist!')"/>
      <UiDialogFooter class="block">
        <UiButton :disabled="!meta.valid || Loader.isLoading"
                  class="ys-button w-full" @click="OTPSend" v-if="statesStore.IsUserExist == 2">
            <Icon v-if="Loader.isLoading" name="svg-spinners:ring-resize" />
            <span v-else>{{ $t("Create a new account") }}</span>
        </UiButton>
        <UiButton :disabled="!meta.valid || Loader.isLoading"
                  v-if="statesStore.IsUserExist == 0"
                  class="ys-button w-full" type="submit" @click="onSubmit">
                  <Icon v-if="Loader.isLoading" name="svg-spinners:ring-resize" />
                  <span v-else>{{ $t("Next") }}</span>
        </UiButton>
      </UiDialogFooter>
      <div class="text-center mt-4">
        <div class="text-sm text-gray-500">
          {{ $t("Other methods") }}
        </div>
        <UiTabsList>
          <UiTabsTrigger value="phone_number" class="text-gray-950 underline hover:text-primary-500 transition-all">
            {{ $t("Sign in with your phone number") }}
          </UiTabsTrigger>
        </UiTabsList>
      </div>
    </div>
    <OTPVerifyForm v-if="!statesStore.OTPIsVerify"/>
    <RegistrationForm v-if="statesStore.OTPIsVerify && statesStore.UserCreateAccount"/>
  </UiTabsContent>
</template>